import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';

import { fetchPage, getFollowedPage } from 'mewe/fetchers/fetch-pages';
import PaymentsApi from 'mewe/api/payments-api';
import PagesApi from 'mewe/api/pages-api';
import StoreApi from 'mewe/api/store-api';
import PurchasesStore from 'mewe/stores/purchases-store';
import { getTotalPriceForItems } from 'mewe/utils/miscellaneous-utils';
import FunctionalUtils from 'mewe/shared/functional-utils';
import { loadScript } from 'mewe/shared/utils';
import storage from 'mewe/shared/storage';
import config from 'mewe/config';
import { inject as service } from '@ember/service';
import dispatcher from 'mewe/dispatcher';
import * as Sentry from '@sentry/ember';

export default class MwPurchase extends Component {
  @service purchase;
  @service analytics;
  @service dynamicDialogs;

  @tracked transactionItems;
  @tracked paymentMethod = 'creditCard';
  @tracked cartTotalPrice;
  @tracked paypalButtonRendered;
  @tracked applePayAvailable;
  @tracked googlePayAvailable;
  @tracked paymentButtonVisible;
  @tracked paymentRequest;
  @tracked purchaseInProgress;
  @tracked purchaseError;

  @tracked promoCode;
  @tracked promoCodeError;
  @tracked appliedPromoCodeLabel;

  constructor() {
    super(...arguments);

    this.purchaseState = PurchasesStore.getState();

    // page subscription is always an only item in the pruchase
    if (this.args.itemsToPurchase?.[0]?.id === 'page') {
      this.pageItem = this.args.itemsToPurchase[0];
      this.pageData = this.args.pageData;
    }

    //Add promo code to the purchase
    if (this.args.promocodeValue) {
      this.promoCode = this.args.promocodeValue;
      this.applyPromoCode();
    }

    // if item is given then purchase only this item, ignore store cart
    if (this.args.itemsToPurchase?.length) {
      this.transactionItems = this.args.itemsToPurchase;
      // remove promo code if there was any applied to cart items,
      // this is a separate "buy now" transaction and previously applied codes need to be cleared in store
      this.removePromoCode();
    } else {
      this.transactionItems = this.purchaseState.cartItems;
      // restore promo code if there was any applied to cart items
      this.addPromoCode(this.purchaseState.appliedPromoCodeLabel || '');
    }

    // this is calculated independently from `cartTotalPrice` in store state
    // because user might by purchasing a single item while having other items in cart
    this.cartTotalPrice = getTotalPriceForItems(this.transactionItems);
  }

  @action
  onInsert(element) {
    this.element = element;

    if (this.canPayWithPaypal) {
      this.installPaypalButton();
    }
  }

  @action
  onDestroy() {
    if (this.appliedPromoCodeLabel) {
      this.removePromoCode();
    }
  }

  getTransactionParams() {
    return {
      productIds: this.pageToSubscribe ? [this.pageToSubscribe.selectedProduct.productId] : this.productIdsToPurchase,
      entityId: this.pageToSubscribe ? this.pageData.id : null,
    };
  }

  get pageProductId() {
    return this.pageItem?.selectedProduct.productId;
  }

  get canPayWithPaypal() {
    return this.isSingleSubscriptionPurchase || this.onlyConsumablesToPurchase;
  }

  get onlyConsumablesToPurchase() {
    return !this.productsToPuchase.find((i) => i.isSubscription);
  }

  get isSingleSubscriptionPurchase() {
    let items = this.args.itemsToPurchase ? this.args.itemsToPurchase : this.transactionItems;
    return items.length === 1 && items[0]?.isSubscription;
  }

  get productsToPuchase() {
    let products = this.args.itemsToPurchase ? this.args.itemsToPurchase : this.transactionItems;
    return products.filter((i) => i.id !== 'page');
  }

  // all products ids excluding page subscriptions which are not included in purchase request
  get productIdsToPurchase() {
    return this.productsToPuchase.map((i) => i.selectedProduct.productId);
  }

  get pageToSubscribe() {
    if (this.args.itemsToPurchase?.length === 1 && this.args.pageData) {
      return this.args.itemsToPurchase[0];
    }
  }

  get providerName() {
    switch (this.paymentMethod) {
      case 'google_pay':
        return 'Google Pay';
      case 'apple_pay':
        return 'Apple Pay';
      case 'paypal':
        return 'PayPal';
      case 'creditCard':
        return __('Credit or Debit Card');
    }
  }

  @action
  afterFormValidated(cardParams) {
    this.cardParams = cardParams;
    this.completePurchase();
  }

  subscribePageWithStripe(pageData) {
    const pageId = pageData.id;
    if (!pageId) return;

    StoreApi.openStripeTransaction(this.getTransactionParams()).then((t) => {
      if (t && t.id) {
        PagesApi.buySubscription(pageId, {
          desktop_purchase: {
            productId: this.pageProductId,
            transactionId: t.id,
          },
        }).then(() => {
          this.afterPageSubscription(pageData, 'stripe');
        });
      }
    });
  }

  afterPageSubscription(pageData, paymentProvider) {
    const pageId = pageData.id;

    // fetch page data with subscription info already
    fetchPage(pageId).then(() => {
      // checking if it's page creation flow
      if (this.args.skin === 'page-creation') {
        const page = getFollowedPage(pageId);
        set(page, 'lastVisited', Date.now());
      }
    });

    dispatcher.dispatch('purchase', 'purchaseSuccess', {
      responseItems: [{ pageData: pageData }],
      productIds: [this.pageProductId],
    });

    this.trackPaymentSuccess({ provider: paymentProvider });
    this.closeAction();
  }

  storePurchaseCallback(data, options = {}) {
    const successCallback = () => {
      dispatcher.dispatch('purchase', 'purchaseSuccess', {
        responseItems: data.items,
        productIds: this.productIdsToPurchase,
        isFtueFlow: this.args.isFtueFlow,
        provider: options.provider,
        // clear cart only during store-checkout flow, if there is an item in 'itemsToPurchase'
        // then it is a direct purchase from some other place which doesn't affect whole cart
        clearCart: !this.args.itemsToPurchase?.length,
      });
    };

    // isFtueFlow means that it has to be Premium purchase
    if (this.args.isFtueFlow) {
      this.dynamicDialogs.openDialog('store/trial-reminder-dialog', {
        provider: options.provider,
        productId: this.productsToPuchase[0].selectedProduct.productId,
        closeCallback: successCallback,
      });
    } else {
      successCallback();
    }

    this.trackPaymentSuccess();
    this.closeAction(true);
  }

  installPaypalButton() {
    const openPaypalRedirection = (data) => {
      const windowParams =
        'status=no,height=730,width=700,resizable=yes,left=' +
        (window.screen.width - 700) / 2 +
        ',top=' +
        (window.screen.height - 600) / 2;
      const paymentWindow = window.open(data, '_blank', windowParams);

      this.paypalResultHandeler = (ev) => {
        let newValue = ev && ev.key === storage.addSuffix(storage.keys.paypalTransactionStatus) && ev.newValue;
        if (!newValue) return;

        try {
          newValue = JSON.parse(newValue);
        } catch (e) {}

        if (newValue.items?.length === 1 && newValue.items[0].itemId === 'page') {
          // as described in SG-29501 comment BE can't publish automatically after puchasing
          // subscription via paypal because of some complexity and it can be done here easily
          PagesApi.publish({ id: this.pageData.id });
          this.afterPageSubscription(this.pageData, 'paypal');
        } else {
          // callback only for non-page subscription purchases
          this.storePurchaseCallback(newValue, { provider: 'paypal' });
        }

        window.removeEventListener('storage', this.paypalResultHandeler);

        if (paymentWindow) {
          setTimeout(() => paymentWindow.close(), 2000);
        }
      };

      // remove listener first in case of paypal popup being opened more than once, listeners would be duplicated
      window.removeEventListener('storage', this.paypalResultHandeler);
      window.addEventListener('storage', this.paypalResultHandeler);

      if (!paymentWindow) {
        FunctionalUtils.error(__('Please disable the Pop-up blocker in your browser.'));
      }
    };

    loadScript(`https://www.paypal.com/sdk/js?client-id=${config.paypalClientId}`).then(() => {
      window.paypal
        .Buttons({
          style: {
            size: 'responsive',
            shape: 'pill',
            layout: 'horizontal',
            tagline: false,
            height: 30,
          },
          funding: {
            allowed: [], // disallow any funding methods
          },

          createOrder: () => {
            return StoreApi.openPaypalTransaction(this.getTransactionParams())
              .then((res) => {
                if (res && res.id) {
                  this.transactionId = res.id;

                  if (this.isSingleSubscriptionPurchase) {
                    const doPaypalSubscriptionPayment = (pageData, productId) => {
                      StoreApi.purchasePaypalSubscription({
                        transactionId: res.id,
                        productId: productId,
                        entityId: pageData && pageData.id,
                      })
                        .then((data) => openPaypalRedirection(data))
                        .catch((response) => {
                          if (response && response.status === 422) {
                            FunctionalUtils.error(__('Your payment is being processed.'));
                            this.trackPaymentError(
                              'paypal',
                              'Payment ERROR (subscribe - /store/paypal/subscription/purchase - payment being processed)'
                            );
                          } else {
                            FunctionalUtils.error(
                              __('Something went wrong with payment processing, please try again later.')
                            );
                            this.trackPaymentError(
                              'paypal',
                              'Payment ERROR (subscribe - /store/paypal/subscription/purchase)'
                            );
                          }
                        });
                    };

                    if (this.pageData?.id) {
                      doPaypalSubscriptionPayment.bind(this)(this.pageData, this.pageProductId);
                    } else {
                      doPaypalSubscriptionPayment(null, this.productIdsToPurchase[0]);
                    }
                  } else if (this.onlyConsumablesToPurchase) {
                    StoreApi.createPaypalOrder({
                      transactionId: res.id,
                      productIds: this.productIdsToPurchase,
                    })
                      .then((data) => openPaypalRedirection(data))
                      .catch(() => {
                        FunctionalUtils.error(
                          __('Something went wrong with payment processing, please try again later.')
                        );
                        this.trackPaymentError('paypal', 'Payment ERROR (create order - /store/paypal/order/create)');
                      });
                  }

                  return res.id;
                } else {
                  FunctionalUtils.error(__('Something went wrong with payment processing, please try again later.'));
                  this.trackPaymentError('paypal', 'Payment ERROR (open transaction - response missing transactionId)'); // probably shouldn't be even possible
                }
              })
              .catch(() => {
                FunctionalUtils.showDefaultErrorMessage();
                this.trackPaymentError('paypal', 'Payment ERROR (open transaction - /store/paypal/transaction/open)');
              });
          },
        })
        .render(this.element.querySelector('.paypal-btn-wrapper'));

      this.paypalButtonRendered = true;
    });
  }

  @action
  updateProgress(value) {
    this.purchaseInProgress = value;
    this.args.updatePurchaseProgress(value);
  }

  trackPaymentSuccess() {
    const params = this.getPaymentTrackingParams();
    this.analytics.sendEvent('purchaseMade', params);
  }

  trackPaymentError(provider, message) {
    Sentry.captureException(new Error(`Purchase Failed. ${provider} - ${message}`));

    const params = this.getPaymentTrackingParams();
    params.reason = 'failed_by_provider';

    this.analytics.sendEvent('purchaseCancelled', params);
  }

  getPaymentTrackingParams() {
    const items = this.pageItem ? [this.pageItem] : this.productsToPuchase;

    const typesMap = {
      subscription: 'subscription',
      consumable: 'individual_item',
      nonconsumable: 'individual_item',
    };

    const params = {
      purchase_provider: this.paymentMethod,
      itemId: [],
      type: [],
    };

    // allowed: 'stripe', 'paypal', 'google_pay', 'apple_pay' ('creditCard' is mapped to 'stripe')
    if (params.purchase_provider === 'creditCard') {
      params.purchase_provider = 'stripe';
    }

    // need to get actual items from the cart items to have all required data for tracking
    // (data from puchase response contains e.g. both "donation" and "dontation-monthly"
    // when purchasing donation subscription)
    items.forEach((i) => {
      params.itemId.push(i.id);
      params.type.push(typesMap[i.selectedProduct.type]);
    });

    return params;
  }

  @action
  onStepClick() {}

  @action
  setPaymentRequest(paymentRequest, result) {
    this.applePayAvailable = result.applePay;
    this.googlePayAvailable = result.googlePay;
    this.paymentButtonVisible = true;
    this.paymentRequest = paymentRequest;
  }

  @action
  paymentRequestClick() {
    if (this.paymentRequest) {
      this.paymentRequest.show();
    }

    StoreApi.openStripeTransaction(this.getTransactionParams()).then((t) => {
      if (this.isDestroyed || this.isDestroying) return;

      if (t?.id) {
        this.transactionId = t.id;
      }
    });
  }

  @action
  completePurchase() {
    const transactionFailback = () => {
      this.dynamicDialogs.openDialog('store/store-fail-dialog', {
        retryFunc: doPurchase.bind(this),
      });

      this.trackPaymentError('stripe', 'Payment ERROR (purchase request /store/desktop/purchase)');
    };

    const cardFailback = () => {
      if (this.isDestroyed || this.isDestroying) return;

      this.purchaseError = true;

      this.updateProgress(false);

      // one tested/known reason for that is incorrcect CVV number or card's exp_date
      // stripe validation guarantees card number only
      this.trackPaymentError('stripe', 'Payment ERROR (save card - /store/stripe/card)');
    };

    const finallyCallback = () => {
      this.updateProgress(false);
    };

    const doPurchase = () => {
      if (this.isDestroyed || this.isDestroying) return;

      // it's set in MwCreditCardForm right on click but here as well in case when
      // doPurchase is triggered after purchase failure from `retryFunc`
      this.updateProgress(true);

      if (this.pageData?.id) {
        this.subscribePageWithStripe(this.pageData);
      } else {
        if (this.productIdsToPurchase.length) {
          StoreApi.openStripeTransaction(this.getTransactionParams())
            .then((t) => {
              const params = {
                productIds: this.productIdsToPurchase,
                transactionId: t.id,
              };

              if (this.appliedPromoCodeLabel) {
                params.promoCode = this.appliedPromoCodeLabel; // don't send empty param, API would fail
              }

              PaymentsApi.storeDoPurchase(params)
                .then((data) => this.storePurchaseCallback(data, { provider: 'stripe' }))
                .catch(transactionFailback)
                .finally(finallyCallback);
            })
            .catch(() => {
              this.trackPaymentError('stripe', 'Payment ERROR (open transaction - /store/desktop/transaction/open)');
            });
        }
      }
    };

    PaymentsApi.updateCard(this.cardParams).then(doPurchase).catch(cardFailback);
  }

  @action
  changePaymentMethod(option) {
    this.paymentMethod = option;

    // promo codes are applicable only for (stripe) creditCard payments
    // clear them for other payment methods
    if (this.paymentMethod !== 'creditCard' && this.appliedPromoCodeLabel) {
      this.removePromoCode();
    }
  }

  @action
  applyPromoCode(e) {
    e?.preventDefault();

    const params = {
      promoCode: this.promoCode,
    };

    if (this.pageData?.id) {
      params.productIds = [this.pageProductId];
      params.entityId = this.pageData.id;
    } else {
      params.productIds = this.productIdsToPurchase;
    }

    StoreApi.checkPromoCode(params)
      .then((data) => {
        if (this.isDestroyed || this.isDestroying) return;
        if (!data?.items) return;

        data.items.forEach((promoItem) => {
          let product;

          if (this.pageData?.id) {
            product = this.pageItem;
          } else {
            product = this.productsToPuchase.find((p) => p.selectedProduct.productId === promoItem.itemId);
          }

          if (product) {
            product.discount = {
              label: params.promoCode,
              value: promoItem.discountPercentage,
              oldPrice: promoItem.priceUSD,
              newPrice: promoItem.discountPriceUSD,
            };
          }
        });

        this.addPromoCode(this.promoCode);
      })
      .catch(() => {
        this.promoCodeError = true;
      });
  }

  @action
  onPromoCodeChange() {
    this.promoCodeError = false;
  }

  @action
  addPromoCode(promoCode) {
    this.purchaseState.set('appliedPromoCodeLabel', promoCode);
    this.appliedPromoCodeLabel = promoCode;
    this.promoCode = '';

    this.cartTotalPrice = getTotalPriceForItems(this.transactionItems);
  }

  @action
  removePromoCode() {
    this.appliedPromoCodeLabel = '';
    this.purchaseState.set('appliedPromoCodeLabel', '');

    // clear code for products passed to checkout
    this.purchaseState.storeItems.forEach((i) => {
      i.discount = {};
    });

    this.cartTotalPrice = getTotalPriceForItems(this.transactionItems);
  }

  @action
  hidePurchaseError() {
    this.purchaseError = false;
  }

  @action
  closeAction(purchaseCompleted) {
    if (!this.isDestroyed && !this.isDestroying) {
      this.args.close(purchaseCompleted);
    }
  }
}
