import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import PostApi from 'mewe/api/post-api';
import Storage from 'mewe/shared/storage';
import EnvironmentUtils from 'mewe/utils/environment-utils';
import PostUtils from 'mewe/utils/post-utils';
import { handleOtherStores } from 'mewe/fetchers/fetch-feed';
import { toPost } from 'mewe/stores/models/post-model';
import FunctionalUtils from 'mewe/shared/functional-utils';

export default class MwBookmarkPost extends Component {
  @service dynamicDialogs;

  @tracked dropdownOpened;

  get bookmark() {
    return this.args.bookmark;
  }

  get post() {
    return this.args.bookmark.post;
  }

  get isPostUnavailable() {
    return !this.post;
  }

  get isNsfwContentSettingDisabled() {
    return Storage.get(Storage.keys.allowNsfwContentSetting) === true ? false : true;
  }

  get isNsfwPreview() {
    return this.post?.photo?.isNsfw && this.isNsfwContentSettingDisabled;
  }

  get showMediaPlayIcon() {
    return !this.isNsfwPreview && (this.post?.isVideo || this.post?.photo?.animated);
  }

  get photoUrl() {
    const p = this.post;
    if (!p) return;

    if (p.medias?.[0]?.photo?._links?.img?.href) {
      return EnvironmentUtils.getImgHost(true) + p.medias[0].photo._links.img.href;
    } else if (p.link?._links?.thumbnail?.href) {
      return p.link._links.thumbnail.href;
    }
  }

  get displayType() {
    if (!this.post) return;

    if (this.post.poll) {
      return 'poll';
    } else if (this.post.files?.length) {
      if (this.post.files[0].audio) {
        return 'audio';
      } else {
        return 'file';
      }
    } else if (this.post.link) {
      return 'link';
    }
  }

  get displayIcon() {
    if (this.isPostUnavailable) {
      return 'svg_cancel';
    }

    switch (this.displayType) {
      case 'poll':
        return 'svg_icon-poll_new';
      case 'audio':
        return 'svg_audio_play';
      case 'file':
        return 'svg_icon-file_new';
      case 'link':
        return 'svg_copy_link';
      default:
        return 'icn_hide';
    }
  }

  get otherTypeText() {
    switch (this.displayType) {
      case 'poll':
        return __('Poll: {question}', {question: this.post.poll.question});
      case 'audio':
        return __('Audio file', {filename: this.post.files[0].name});
      case 'file':
        return this.post.files[0].name ? __('Document: {filename}', {filename: this.post.files[0].name}) : __('Document');
      case 'link':
        return __('Link');
    }
  }

  @action
  toggleDropdown() {
    this.dropdownOpened = !this.dropdownOpened;
  }

  @action
  closeDropdown() {
    this.dropdownOpened = false;
  }

  @action
  removeBookmark() {
    this.args.removeBookmark(this.bookmark);
  }

  @action
  openPost() {
    let { scope, scopeId } = PostUtils.getPostScopeAndId(this.post);

    PostApi.getPostDetails({
      scope: scope,
      groupId: scopeId,
      pageId: scopeId,
      eventId: scopeId,
      threadId: scopeId,
      postItemId: this.post.postItemId,
    })
      .then((data) => {
        handleOtherStores(data);

        let postE = toPost(data.post);

        this.dynamicDialogs.openDialog('single-post-dialog', {
          post: postE,
          scope: scope,
        });
      })
      .catch((resp) => {
        if (resp && resp.status === 404) {
          FunctionalUtils.error(__('Sorry, this post has been deleted by the owner.'));
        }
      });
  }

  @action
  clickHandler(e) {
    if (e.target.closest('.post_header-info')) return;
    if (e.target.closest('button')) return; // close button for unavailable post
    else this.openPost();
  }
}
