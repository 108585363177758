/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { action, computed, set, get } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { A } from '@ember/array';
import ContactsApi from 'mewe/api/contacts-api';
import GroupStore from 'mewe/stores/group-store';
import { ds } from 'mewe/stores/ds';
import CurrentUserStore from 'mewe/stores/current-user-store';
import GroupUtils from 'mewe/utils/group-utils-light';
import { Target } from 'mewe/constants';
import fetchEvents from 'mewe/fetchers/fetch-events';
import { processContacts, search } from './utils';
import dispatcher from 'mewe/dispatcher';
import { addObserver, removeObserver } from '@ember/object/observers';

export default class MwShareWith extends Component {
  @service router;
  @service account;
  @service dynamicDialogs;

  @tracked contactsInited;
  @tracked searchValue;
  @tracked contacts;

  element;

  constructor() {
    super(...arguments);
    this.nextPage = '';
    this.searchValue = '';
    this.groupsState = GroupStore.getState();
    this.eventsState = ds.events.for('upcoming-events');
    this.contactsInited = false;

    if (this.args.target == Target.EVENT) {
      if (!this.eventsState.items.length) {
        fetchEvents('events', 'upcoming-events');
      }
    }

    if (this.args.target == Target.PRIVATEPOST) {
      this.contacts = A();
      this.userState = CurrentUserStore.getState();
      this.contactsFetchStarted = false;
    }

    addObserver(this, 'shareWithType', this.resetSearch);
  }

  @action
  onInsert(element) {
    this.element = element;
    if (this.args.target == Target.PRIVATEPOST && !this.contactsFetchStarted) {
      this.loadContacts();
      this.contactsFetchStarted = true;
    }
  }

  @action
  onDestroy() {
    removeObserver(this, 'shareWithType', this.resetSearch);
  }

  loadContacts() {
    const apiCall = (p) => ContactsApi.list(p);
    const userOrContactProperty = 'list';

    apiCall({ nextPage: this.nextPage })
      .then((data) => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        const processedContacts = processContacts(data[userOrContactProperty]);

        this.contacts.pushObjects(processedContacts);

        if (this.args.selectedContacts) {
          this.contacts = A([
            ...this.contacts.map((contact) => {
              const preselected = this.args.selectedContacts.find((pc) => pc.id === contact.id);
              return { ...contact, isSelected: !!preselected };
            }),
          ]);
        }

        this.nextPage = data._links?.nextPage?.href;

        this.element.style.minHeight = '51px'; // hack for #SG-14563
      })
      .finally(() => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }
        this.contactsInited = true;
      });
  }

  @computed('args.target', 'properGroups')
  get groups() {
    if (this.args.target !== Target.GROUP) {
      return A();
    } else {
      return A([
        ...this.properGroups.map((g) => {
          return {
            id: g.id,
            name: g.name,
            profileImage400x400FullUrl: g.profileImage400x400FullUrl,
            _links: g._links,
            isSelected: false,
          };
        }),
      ]);
    }
  }

  @computed('searchValue', 'args.target')
  get searchResults() {
    const str = this.searchValue.toLowerCase().trim();
    if (str.length) {
      const selectedItems = this.targets.filterBy('isSelected', true);
      return search(str, this.args.target, selectedItems, this.args.administrated, this.groups);
    } else {
      return null;
    }
  }

  @computed('searchResults.isPending', 'contactsInited', 'args.target')
  get loading() {
    return this.searchResults?.isPending || (this.args.target === Target.PRIVATEPOST && !this.contactsInited);
  }

  resetSearch() {
    this.searchValue = '';
  }

  @computed('args.target', 'searchValue')
  get style() {
    switch (this.args.target) {
      case Target.PAGE:
        return 'style-page';
      case Target.GROUP:
        return 'style-group';
      case Target.EVENT:
        return 'style-event';
      case Target.PRIVATEPOST:
        return 'style-privatepost';
      default:
        return '';
    }
  }

  @computed('args.target')
  get placeholderText() {
    switch (this.args.target) {
      case Target.GROUP:
        return __('Search Groups');
      case Target.EVENT:
        return __('Search Events');
      case Target.PRIVATEPOST:
        return __('Search');
      default:
        return '';
    }
  }

  @computed('groupsState.confirmedGroups')
  get properGroups() {
    return GroupUtils.getPermittedToPostGroups(this.groupsState.confirmedGroups, false);
  }

  @computed('args.administrated.items', 'args.target', 'contacts', 'eventsState.items.length', 'groups')
  get targets() {
    let targets = [];

    switch (this.args.target) {
      case Target.PAGE:
        targets = this.args.administrated?.items;
        break;
      case Target.GROUP:
        targets = this.groups;
        break;
      case Target.EVENT:
        targets = this.eventsState?.items.map((item) => ({
          ...item.__data,
          isSelected: item.id === this.lastSelectedEventId,
        }));
        break;
      case Target.PRIVATEPOST:
        targets = this.contacts;
        break;
    }

    return targets;
  }

  @computed('targets', 'searchResults.{content,isFulfilled}', 'searchValue.length')
  get targetsDisplay() {
    const isSearchValue = this.searchValue.length !== 0;
    const targetsToDisplay = this.searchResults && isSearchValue ? this.searchResults?.content : this.targets;
    return targetsToDisplay;
  }

  @computed('args.target')
  get inputType() {
    if (this.args.target == Target.PRIVATEPOST) {
      return 'checkbox';
    } else {
      return 'radio';
    }
  }

  @action
  unSelectContact(clicked) {
    this.updateTargets(clicked);
    this.args.unSelectContact?.(clicked);
  }

  @action
  setShareWithType(type) {
    this.resetSearch();
    this.shareWithType = type;
  }

  @action
  closeSearch() {
    this.resetSearch();
  }

  @action
  afterEventsTransitionFromPicker() {
    this.args.afterEventsTransitionAction?.();
  }

  @action
  goToEvents() {
    this.args.afterEventsTransitionAction?.();
    this.router.transitionTo('app.events');
    this.dynamicDialogs.closeAll();
  }

  @action
  openInvitationsDialog() {
    dispatcher.dispatch('contact', 'openContactInvitationDialog');
  }

  @action
  onScroll() {
    if (this.args.target == Target.PRIVATEPOST) {
      if (this.nextPage) {
        this.loadContacts();
      }
    } else if (this.args.target === Target.EVENT) {
      // save selected event because fetching more will overwrite current state
      // so it has to be re-selected after any change in events list
      this.lastSelectedEventId = this.targets.find((t) => t.isSelected)?.id;
      fetchEvents('events', 'upcoming-events', true);
    }
  }

  @action
  updateTargets(clickedItem) {
    const target = this.targets.find((t) => t.id === clickedItem.id);
    if (!target) return;

    const newValue = !target.isSelected;

    // PRIVATEPOST can have multiple targets selected (there are checkboxes)
    if (this.args.target === Target.PRIVATEPOST) {
      set(target, 'isSelected', newValue);

      if (target.isSelected) {
        this.args.onSelectContact(target);
      } else {
        this.args.unSelectContact(target);
      }
    }
    // other destinations can have only one target selected (there are radio inputs)
    else {
      this.targets.forEach((t) => set(t, 'isSelected', t.id === target.id));
      this.args.onSelect(target);
    }

    // if item was clicked in search results then update those results as well to reflect actual state
    if (this.searchResults?.content?.length) {
      const searchTarget = this.searchResults.content.find((c) => c.id === clickedItem.id);

      if (this.args.target === Target.PRIVATEPOST) {
        set(searchTarget, 'isSelected', newValue);
      } else {
        this.searchResults.content.forEach((t) => set(t, 'isSelected', t.id === searchTarget.id));
      }
    }
  }
}
