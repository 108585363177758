import Component from '@glimmer/component';
import Scrolling from 'mewe/utils/scrolling-utils';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { next } from '@ember/runloop';
import { addObserver, removeObserver } from '@ember/object/observers';
import ContactsApi from 'mewe/api/contacts-api';
import ContactsStore from 'mewe/stores/contacts-store';

export default class MwFollowRequests extends Component {
  @tracked scrollEl;

  scrolling = Scrolling();

  constructor() {
    super(...arguments);

    if (this.args.requests?.items?.length) {
      this.setScrolling();
    }

    // eslint-disable-next-line ember/no-observers
    addObserver(this, 'args.requests.items.length', this, this.setScrolling);
  }

  setScrolling() {
    this.scrolling.unbindScrollDown(this.scrollEl);

    next(() => {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      this.scrolling.bindScrollDownElement(this.scrollEl, () => this.loadMorePending(), 500);
    });
  }

  willDestroy() {
    removeObserver(this, 'args.requests.items.length', this, this.setScrolling);
    this.scrolling.unbindScrollDown(this.scrollEl);
    super.willDestroy();
  }

  loadMorePending() {
    const collection = this.args.requests;

    if (!collection.nextPage) {
      return;
    }

    ContactsStore.send('update', collection.id, { isFetchingMore: true });

    ContactsApi.pending({ nextPage: collection.nextPage })
      .then((r) => {
        if (r.list?.length) {
          ContactsStore.send(
            'handle',
            collection.id,
            r.list.map((el) => Object.assign(el.user, { followRequestReceived: el.followRequestId }))
          );
        }

        ContactsStore.getState({ id: collection.id }).set('nextPage', r._links?.nextPage?.href);
      })
      .finally(() => {
        ContactsStore.send('update', collection.id, { isFetchingMore: false });
      });
  }

  @action
  setScrollEl(el) {
    this.scrollEl = el;
  }
}
