import EmberObject from '@ember/object';
import { getOwner } from '@ember/application';
import PostApi from 'mewe/api/post-api';
import ChatStore from 'mewe/stores/chat-store';
import FunctionalUtils from 'mewe/shared/functional-utils.js';

import dispatcher from 'mewe/dispatcher';
import PS from 'mewe/utils/pubsub';
import { nonFalsyEmpty } from 'mewe/utils/function-utils';
import { Target, Theme, maxAttachmentsPerPost } from 'mewe/constants';

export default {
  /**
   * Returns basic options build upon given params
   *
   * @param optData should contain defined text and postboxHandler
   * additionaly you can set fileId
   */
  getStandardPostingOptions: function (optsData, postbox) {
    if (postbox.link) optsData.link = postbox.linkController.getLinkData();

    optsData = Object.assign(optsData, this.getAudioParams(postbox));
    optsData = Object.assign(optsData, this.getExistingFilesIds(postbox));

    const {
      text,
      link,
      audioId,
      existingAudioItem,
      poll,
      fileIds,
      parentId,
      photos,
      imageIds,
      album,
      threadIds,
      existingFileIds,
      existingAudioId,
      mediaIds,
      filesOrder,
      existingVideoItem,
      schedule,
      stickers,
      commentsDisabled,
    } = optsData;

    const options = nonFalsyEmpty({
      text,
      link,
      audioId,
      existingAudioItem,
      poll,
      fileIds,
      parentId,
      photos,
      imageIds,
      album,
      threadIds,
      existingFileIds,
      existingAudioId,
      mediaIds,
      filesOrder,
      existingVideoItem,
      schedule,
      stickers,
      commentsDisabled,
    });

    if (postbox.postingType == 'everone') {
      options.everyone = true;
    } else if (postbox.postingType == 'friends') {
      options.closeFriends = true;
    }

    if (postbox.presetEmojisList && postbox.presetEmojisList.length) {
      options.emojisPreset = postbox.presetEmojisList;
    }

    if (postbox.sticker) options.stickers = [postbox.sticker];

    if (postbox.pageId) options.postedByPage = postbox.postedByPage || postbox.page.isOwnerAdmin; // we still have to check for owner/ admin - SG-20923

    if (postbox.postToRepost?.isEditing) options.edit = true;

    return options;
  },

  /*
   * We can post only one audio file in post now - even during reposting user can add his own audio, so we need to differentiate if
   * it is new just recorded audio or existing one - SG-21975
   */
  getAudioParams: function (postboxObj) {
    if (postboxObj.audioRecordingBlob) {
      return { audioId: postboxObj.audioFileId };
    } else if (postboxObj.audioFileId) {
      return { existingAudioItem: postboxObj.audioFileId };
    }
  },

  getExistingFilesIds: function (postboxObj) {
    const preselectedFiles = postboxObj.args.preselectedFiles;

    if (preselectedFiles?.length) {
      // SG-21870 reposting existing audio file from chat, use 'existingAudioId' param in that case
      if (preselectedFiles.length === 1 && preselectedFiles[0].duration) {
        return { existingAudioId: preselectedFiles[0].id };
      } else {
        return { existingFileIds: preselectedFiles.map((f) => f.id) };
      }
    }

    return {};
  },

  share: function (params, postboxHandler) {
    if (params.edit) return dispatcher.dispatch('feed', 'editPost', params, postboxHandler);

    if (postboxHandler) postboxHandler.isSharing = true;
    else postboxHandler = EmberObject.create(params); // may be not defined in case of creating google/office document, but then groupId/eventId should be there and can be used for getting scope

    const scope = this.scope(postboxHandler);

    if (postboxHandler?.postingType === 'everyone') params.everyone = true;
    else if (postboxHandler?.postingType === 'friends') params.closeFriends = true;

    PostApi.addPost({ params, scope })
      .then((data) => {
        if (params.album) {
          PS.Pub('album.shared', params.album);
        }
        if (data.medias?.length) {
          const postWithMedias = data.post || { medias: data.medias }; // posting to MC don't return post object, just medias
          PS.Pub('photoStream.update', { post: postWithMedias, scope: Theme.MYCLOUD, type: 'add' });
        }

        if (postboxHandler?.args?.afterShare) postboxHandler.args.afterShare();

        if (postboxHandler?.closeCompletely) postboxHandler.closeCompletely();

        if (params?.callback) params.callback(data);

        let post = data.post;

        if (!post) return; // in case we are posting media or file to mycloud - there is no post

        // thumbnail sizes are not in response and should be taken from previous link info fetching
        if (params.link?.thumbnailUrl && postboxHandler.linkThumbUrlHeight) {
          if (post.link)
            post.link.thumbnailSize = {
              width: postboxHandler.linkThumbUrlWidth,
              height: postboxHandler.linkThumbUrlHeight,
            };
        }

        if (params.threadIds?.length) {
          post.threadId = params.threadIds[0];
        }

        dispatcher.dispatch('feed', 'addPost', post);

        if (params.schedule) {
          FunctionalUtils.info(__('Your post has been scheduled'));
        } else if (!this.isPostingToCurrentLocation(scope, post, postboxHandler.router)) {
          FunctionalUtils.info(__('Post Shared'));
        }

        if (post.pending) {
          const currentRouteName = postboxHandler.router?.currentRouteName;

          if (currentRouteName === 'app.event.feed' && post.eventId)
            dispatcher.dispatch('app', 'goTo', 'app.event.feed-pending', [post.eventId]);
          if (currentRouteName === 'app.group.index.feed' && post.groupId)
            dispatcher.dispatch('app', 'goTo', 'app.group.index.feed-pending', [post.groupId]);
        }
      })
      .catch((data) => {
        if (params.poll && data.data && data.data.message === 'New Poll cannot end in the past.') {
          FunctionalUtils.error(__('Check your system time settings. Poll cannot end in the past.'));
        } else if (data.data && data.data.errorCode === 700) {
          postboxHandler.dynamicDialogs.openDialog('store/store-item-storage-dialog', { storageAlert: true });
        } else if (data.data && data.data.errorCode === 707) {
          // 707 is for album size limit (1000) reached
          FunctionalUtils.error(__('Album size limit reached.'));
        } else if (data.data && data.data.errorCode === 623) {
          FunctionalUtils.showLimitedPostingNotice();
        } else if (data.data && data.data.errorCode === 702) {
          FunctionalUtils.error(
            __('There can be maximum of {count} attachments per post.', {
              count: maxAttachmentsPerPost,
            })
          );
        } else if (!data.data) FunctionalUtils.showErrorRetryPost(() => this.share(params, postboxHandler));
        else FunctionalUtils.showDefaultErrorMessage();
      })
      .finally(() => {
        if (postboxHandler?.alwaysAfterPosting) {
          postboxHandler.alwaysAfterPosting();
        }
      });
  },

  isPostingToCurrentLocation: function (fullScopeName, post, router) {
    let scope, scopeId, scopeParts;

    // scope in format group/{id}, can be also /pages/page/{id}
    if (fullScopeName.indexOf('/')) {
      scopeParts = fullScopeName.split('/');
      scope = fullScopeName.split('/')[0];
      scopeId = fullScopeName.split('/')[scopeParts.length - 1]; // last element in case of /pages/page/{id}
    } else {
      scope = fullScopeName;
    }

    const currentRouteName = router.currentRouteName;
    const currentRoutePrams = router.currentRoute.attributes;
    const isPostingToCurrentLocation =
      (scope === 'home' && ~currentRouteName.indexOf('app.myworld.index')) ||
      (scope === Theme.GROUP &&
        ~currentRouteName.indexOf('app.group.index.feed') &&
        (scopeId === currentRoutePrams.appGroup.groupId || !scopeId)) ||
      (scope === Theme.EVENT &&
        ~currentRouteName.indexOf('app.event.feed') &&
        (scopeId === currentRoutePrams.eventId || !scopeId)) ||
      (scope === Theme.PAGE &&
        ~currentRouteName.indexOf('app.publicid.feed') &&
        (scopeId === currentRoutePrams.pageId || !scopeId)) ||
      (scope === Theme.PAGES &&
        ~currentRouteName.indexOf('app.publicid.feed') &&
        (scopeId === currentRoutePrams.pageId || !scopeId)) ||
      (scope &&
        scope.indexOf('privateposts') !== -1 &&
        (ChatStore.getState().get('selectedThreadId') === post.threadId ||
          ChatStore.getState()
            .get('openedThreads')
            .find((t) => t.id === post.threadId))); // shared to chat that is not visible/opened

    return isPostingToCurrentLocation;
  },

  scope: function (postbox) {
    // usecase: sharing link without option 'include original post' creates new post but scope is selected in share dropdown
    if (postbox.postToShare) {
      if (postbox.pageId) return `pages/page/${postbox.pageId}`;
      return postbox.postToShareTarget;
    }

    if (postbox.groupId && postbox.groupId != 'contacts' && postbox.groupId != Theme.MYCLOUD)
      return `group/${postbox.groupId}`;

    if (postbox.threadIds) return `privateposts/${postbox.threadIds}`;

    if (postbox.eventId) return `event/${postbox.eventId}`;

    if (postbox.pageId) return `pages/page/${postbox.pageId}`;

    if (postbox.target == Target.CONTACTS) return 'home';

    return Theme.MYCLOUD;
  },
};
