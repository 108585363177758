/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { A } from '@ember/array';

import ContactsApi from 'mewe/api/contacts-api';
import { processContacts, search } from 'mewe/pods/components/others/mw-share-with/utils';
import Scrolling from 'mewe/utils/scrolling-utils';
import IndicatorsStore from 'mewe/stores/indicators-store';
import { Target, TargetContact, maxFavorites } from 'mewe/constants';
import dispatcher from 'mewe/dispatcher';
import FollowingApi from 'mewe/api/following-api';
import { inject as service } from '@ember/service';

export default class MwSharingPublicPost extends Component {
  @service account;

  scrolling = Scrolling();
  indicators = IndicatorsStore.getState();

  @tracked followers = A();
  @tracked followersLoading;
  @tracked favorites = A();
  @tracked favoritesLoading;
  @tracked allContacts = A();
  @tracked fetchingContacts = false;
  @tracked isFavoriteLimitReached;
  @tracked friendsPicker = false;
  @tracked searchValue = '';

  constructor() {
    super(...arguments);

    if (this.isTargetContact) {
      dispatcher.dispatch('contacts', 'fetchIndicators');
      this.loadCounter();
    }

    this.account.activeUser.deferred.promise.then(() => {
      this.setPostingType(this.account.activeUser.public ? TargetContact.PUBLIC : TargetContact.PRIVATE);
    });
  }

  @action
  onInsert(element) {
    this.element = element;
  }

  @action
  onDestroy() {
    this.scrolling.unbindScrollDown(this.element.querySelector('.friends_content'));
  }

  async loadFollowers() {
    this.followersLoading = true;
    let users = await FollowingApi.favoriteCandidates({ limit: 15, search: this.searchValue });
    let list = users?.list ? A([...users.list]) : A();

    this.followers = list;
    this.followersLoading = false;
  }

  async loadFavorites() {
    this.favoritesLoading = true;
    let users = await FollowingApi.followersFavorite({ limit: 15, search: this.searchValue });
    let list = users?.list ? A([...users.list]) : A();
    let usersList = A([...list.map((item) => Object.assign(item, item.user))]);

    this.favorites = usersList;
    this.favoritesLoading = false;
  }

  async loadCounter() {
    let counters = await FollowingApi.counters();
    let isFavLimitReached = (counters?.favorites || 0) >= maxFavorites;
    this.isFavoriteLimitReached = isFavLimitReached;
  }

  get onlyContacts() {
    return this.allContactsSorted.filterBy('closeFriend', false);
  }

  get friends() {
    return this.allContactsSorted.filterBy('closeFriend', true);
  }

  get allContactsSorted() {
    //sortBy will return array without support of Ember.Array apis
    return A([...this.allContacts.sortBy('user.name')]);
  }

  get isPublic() {
    if (this.account.activeUser.public && !this.args.postingType) {
      return TargetContact.PUBLIC;
    }

    return this.args.postingType === TargetContact.PUBLIC;
  }

  get isPrivate() {
    if (!this.account.activeUser.public && !this.args.postingType) {
      return TargetContact.PRIVATE;
    }

    return this.args.postingType == TargetContact.PRIVATE;
  }

  get scrollStyle() {
    const isRtl = document.dir == 'rtl',
      w = isRtl ? '50%' : '-50%';
    return htmlSafe(`transform: translateX(${this.friendsPicker ? w : 0})`);
  }

  get isFriends() {
    return this.args.postingType == TargetContact.FRIENDS;
  }

  loadContacts(offset = 0) {
    this.fetchingContacts = true;
    this.args.fetchingContactsUpdate();

    ContactsApi.list({
      maxResults: 30,
      offset: offset,
    }).then((data) => {
      if (this.isDestroyed || this.isDestroying) return;

      const ids = this.allContacts.map((i) => i.id);

      const users = data.list.filter((el) => !~ids.indexOf(el.user.id));

      this.allContacts.pushObjects(processContacts(users));
      this.fetchingContacts = false;
      this.args.fetchingContactsUpdate();

      if (users.length) {
        this.scrolling.bindScrollDownElement(
          this.element.querySelector('.friends_content'),
          () => {
            this.loadContacts(this.allContacts.length);
          },
          500
        );
      }
    });
  }

  get searchResults() {
    const str = this.searchValue.trim();

    if (str.length) {
      return search(str, Target.CONTACTS_FRIENDS, this.friends);
    } else {
      return null;
    }
  }

  get showNoFollowingPlaceholder() {
    return !this.followers.length && !this.followersLoading && !this.favorites.length && !this.favoritesLoading;
  }

  @action
  toggleFriendsPicker(e) {
    e?.stopPropagation();

    if (!this.friendsPicker) {
      this.loadFollowers();
      this.loadFavorites();
    }

    if (this.isFriends && !this.indicators.favorites) {
      this.setPostingType(TargetContact.PRIVATE);
    }

    this.friendsPicker = !this.friendsPicker;

    this.allContacts = A();
    this.loadContacts();
  }

  @action
  toggleFriendsPickerIfNoCloseFriends() {
    if (!this.indicators.favorites) {
      this.toggleFriendsPicker();
    } else {
      this.setPostingType(TargetContact.FRIENDS);
    }
  }

  @action
  setPostingType(type) {
    this.args.setPostingType(type);
  }

  @action
  toggle(user, fromSearch) {
    if (user.closeFriend) {
      this.indicators.set('favorites', this.indicators.favorites - 1);
    } else {
      this.indicators.set('favorites', this.indicators.favorites + 1);
      if (!this.isFriends) {
        this.setPostingType(TargetContact.FRIENDS);
      }
    }

    dispatcher.dispatch(
      'contact',
      'toggleFriend',
      user,
      () => {
        if (fromSearch) {
          this.allContacts = A();
          this.loadContacts(0);
        }
      },
      () => {
        if (user.closeFriend) {
          this.indicators.set('favorites', this.indicators.favorites + 1);
        } else {
          this.indicators.set('favorites', this.indicators.favorites - 1);
        }
      }
    );
  }

  @action
  addFavoriteCb(user) {
    this.favorites.addObject(user);
    this.followers.removeObject(user);
  }

  @action
  removeFavoriteCb(user) {
    this.followers.addObject(user);
    this.favorites.removeObject(user);
  }

  @action
  closeSearch() {
    this.searchValue = '';
  }

  get isTargetContact() {
    return this.args.target == Target.CONTACTS;
  }

  @action
  togglePostComments() {
    this.args.togglePostComments?.();
  }
}
