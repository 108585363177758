import { A } from '@ember/array';
import SimpleUser from 'mewe/stores/models/simple-user-model';
import SimpleGroup from 'mewe/stores/models/simple-group-model';
import PageModel from 'mewe/stores/models/page-model';
import GroupModel from 'mewe/stores/models/group-model';
import BookmarkModel from 'mewe/stores/models/bookmark-model';
import { serializer as s } from 'mewe/utils/store-utils/serializers/json-serializer';
import { retryHttp500UntilDone } from 'mewe/utils/fetch-utils';
import Verbose from 'mewe/utils/verbose';
import { attr } from 'mewe/utils/store-utils/model/attribute';

const verbose = Verbose({ prefix: '[Fetch Collection]', color: 'blue', enabled: true }).log;

const IS_FETCHING = 'isFetching';
const IS_FETCHING_MORE = 'isFetchingMore';

// guard
export const promiseConnectionCheck = (promise) => {
  return new Promise((resolve, reject) => {
    retryHttp500UntilDone(() => promise)
      .connectionIssue((err) => {
        verbose('Connection issue');
        reject(Error(`[Fetch Collection] Connection issue`, { cause: err }));
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        verbose('Fail ', err);
        return reject(Error(`[Fetch Collection] Promise failed`, { cause: err }));
      });
  });
};

export const promiseFlagged = (flag) => (fetch, fechable) => {
  fechable.set(flag, true);

  fechable.set('promise', fetch);

  return fetch;
};

export const unsetFlag = (collection) => () => {
  if (collection.get(IS_FETCHING)) collection.set(IS_FETCHING, false);
  if (collection.get(IS_FETCHING_MORE)) collection.set(IS_FETCHING_MORE, false);
};

export const isMore = (params) => params && (params.nextPage || params.offset > 0);

export const promiseIsFetching = (promise, collection, params) =>
  promiseFlagged(isMore(params) ? IS_FETCHING_MORE : IS_FETCHING)(promise, collection);

export const intoHash = (hash) => (array) =>
  Array.isArray(array)
    ? array.map((el) => {
        hash[el.id] = el;
      })
    : A();

export const toSimpleUser = (user) => s.deserializeOne(SimpleUser, user);

export const toSimpleGroup = (group) => s.deserializeOne(SimpleGroup, group);

export const toPage = (data) => s.deserializeOne(PageModel, data);

export const toGroup = (data) => s.deserializeOne(GroupModel, data);

export const toBookmark = (data) => s.deserializeOne(BookmarkModel, data);

export const toFollower = (user) =>
  s.deserializeOne(SimpleUser.extend({ followed: attr('string'), roleName: attr('string') }), user);

export const toBanned = (data) =>
  s.deserializeOne(
    SimpleUser.extend({ banned: attr('string'), bannedBy: attr('string') }),
    Object.assign(data.user, { banned: data.date, bannedBy: data.bannedBy })
  );

export const fetchUtil = (promise, collection, params) =>
  promiseIsFetching(promiseConnectionCheck(promise), collection, params);
